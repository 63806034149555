import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/jon/projects/shanti-aid-gatsby/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Thank you to The National Lottery Community Fund for supporting our Share & Repair Project.`}</h1>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b56c5e4d434bcbca9d642fd182d60b44/c1b63/national-lottery-2023.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAC4jAAAuIwF4pT92AAABnElEQVQoz52TXWsTQRSG9/95J73z1kvvLP4CbwRREJoGpCCpGjT0w9KSXYNsPppNs2mTdteLki5o01IIbRKSTbJfmXlk06ZVL7TtgTPvmcPwMMN7RpFSEkesQojreta/ayjx4nnedeO+oD+Auq6TSqWwbfsSKgRSyMuU/8m/zk2BqqqSTqdpNptToOD+t1Qcx6FSMcjn86yvrRHKCNEdE7VdJm0XMQoR4QTh+sipBohhgPQi5DhkcjYg+tFD9DzkKEDJZDK0WsfU63USiwlK33TCNzXGCZPBvEb/ySaDuc/0H69z8WCZ/tMs7osinYcf6T7KMHiex31t0H+mEdROUUzTnL7dsg5IJhdRNZX2W4PzpQqdlwXc9w1OkgU6SQN/45BhospwYYfRcgP3VZmg9JPI6RAYx4iLEYplWWiaSqlUpLy9zf7BPnq5QK2xx+rWF5Y+vOPT5go71u7tXY6hudxXbNvC931OWyd8t23MapW92i5aNovTPCL2Sk6uJiDWWS1vnFbu4uBtZlSZ/RApxZXezFS8n+XvP+pfwF+m0DmZx+HtLQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "National Lottery logo",
            "title": "National Lottery logo",
            "src": "/static/b56c5e4d434bcbca9d642fd182d60b44/5a190/national-lottery-2023.png",
            "srcSet": ["/static/b56c5e4d434bcbca9d642fd182d60b44/772e8/national-lottery-2023.png 200w", "/static/b56c5e4d434bcbca9d642fd182d60b44/e17e5/national-lottery-2023.png 400w", "/static/b56c5e4d434bcbca9d642fd182d60b44/5a190/national-lottery-2023.png 800w", "/static/b56c5e4d434bcbca9d642fd182d60b44/c1b63/national-lottery-2023.png 1200w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <hr></hr>
    <h3>{`Here our service users come and share their experiences, confide in and gain support from each other, as well as support from Shanti Aid.`}</h3>
    <h3>{`We engage in training courses and offer support on how to gain access to further education and employment, so that service users become confident and independent.`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      