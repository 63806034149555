import React from 'react'
import { Card, Container } from 'react-bootstrap'
import Layout from './Layout'

function News({ children }) {

    console.log('Here in default layout')
    return (

        <Layout title="News">
            <Container>
                <Card className="text-center mt-5 mb-5">
                    <Card.Body>
                        {children}
                    </Card.Body>
                </Card>
            </Container>
        </Layout>
    )
}

export default News